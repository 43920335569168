import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceItem from "./ServiceItem";
import sheet from "../resources/images/sheet_rock.png";
import paint from "../resources/images/paint.png";
import electrical from "../resources/images/electrical.png";
import concreting from "../resources/images/concrete.png";
import additions from "../resources/images/additions.png";
import improvements from "../resources/images/rennovation.png";
import decks from "../resources/images/deck.png";
import siding from "../resources/images/siding.png";
import carpet from "../resources/images/carpet.png";
import drilling from "../resources/images/drilling_wall.png";
import hardwood from "../resources/images/hardwood.png";

const servicesArray = [
  {
    url: sheet,
    title: "Sheet Rock",
  },
  {
    url: paint,
    title: "Painting",
  },
  {
    url: electrical,
    title: "Electrical",
  },
  {
    url: concreting,
    title: "Concreting",
  },
  {
    url: additions,
    title: "Additions",
  },
  {
    url: improvements,
    title: "Renovations",
  },
  {
    url: decks,
    title: "Decks + Porches",
  },
  {
    url: siding,
    title: "Vinyl Siding",
  },
  {
    url: carpet,
    title: "Carpeting + Tiling",
  },
  {
    url: hardwood,
    title: "Laminate + Hardwood",
  },
];

const ServicesSection = () => {
  return (
    <Box
    id="services"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textTransform: "uppercase",
            // fontSize: "15vw",
            fontSize: "min(100px, 15vw)",
            // zIndex: "999",
            marginBottom: "-1.2rem",
            background: "red",
            color: "white",
            width: "min(1000px, 90%)",
          }}
        >
          Services
        </Typography>
        <Container>
          <img
            src={drilling}
            alt="drill"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              border: "10px dashed black",
              maxWidth: "700px",
              loading: "lazy",
            }}
          />
        </Container>
      </Container>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          maxWidth: "1000px",
        }}
      >
        {servicesArray.map((service, i) => (
          <Grid item key={i} sx={{ background: "red" }}>
            <ServiceItem service={service} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesSection;

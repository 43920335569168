import { Typography, Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { FaToolbox } from "react-icons/fa"
import { Gallery } from "react-grid-gallery";

const GalleryView = () => {
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    fetch("https://sonshine-api.vercel.app/gallery")
      .then((res) => res.json())
      .then((res) => {
        var i = [];
        return res.map((id) => {
          const image = {
            src: "https://drive.google.com/uc?id=" + id,
            width: 320,
            height: 174,
          };
          i.push(image);
          setImages(i);
          return id;
        });
      })
      .catch(() => {
        console.log("No response.");
      });
  }, []);
  return (
    <Box
    id="portolio"
      sx={{
        padding: "1rem",
      }}
    >
      <Grid
        container
        sx={{
          height: "300px",
          alignItems: "center",
          justifyContent: "center",
        backgroundColor: "primary.main", 
        padding: "1rem"
        }}
      >
        <Grid item color="white">
          <FaToolbox size={50}/>
          <Typography variant="h5" color="secondary">Don't take our word for it </Typography>
          <Typography
            variant="h3"
            color="white"
            sx={{ textTransform: "uppercase" }}
          >
            See for your self
          </Typography>
        </Grid>
      </Grid>
      <Gallery images={images} />
    </Box>
  );
};

export default GalleryView;

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";

const iconsStyle = {
  fontSize: "25px",
  color: "red",
};

const typographyStyle = {
  ":hover": {
    color: "red",
    transition: "all 0.2s ease-in-out",
  },
};

const FooterSection = () => {
  const sendEmail = () => {
    window.open("mailto:sonshinedevelopments.com");
  };

  const callPhone = () => {
    window.open("callto:+12298159535");
  };

  const openFb = () => {
    window.open(
      "https://www.facebook.com/permalink.php?story_fbid=pfbid0KR2UvKygGtgoKV4XnNojX8q5m7bbZvJkhDeqEoy6b9J5w84pMjTqKzaxYUNZZZ23l&id=100093359216772"
    );
  }
  const openIg = () => {
      window.open(
        "https://instagram.com/sonshine_developments?igshid=NTc4MTIwNjQ2YQ=="
      );
  };

  return (
    <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".4rem",
        background: "black",
        padding: "50px",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: ".5rem",
        }}
      >
        <AiFillInstagram style={iconsStyle} onClick={openIg} />
        <AiFillFacebook style={iconsStyle} onClick={openFb}/>
      </Container>
      <Container style={{ color: "white" }}>
        <Typography sx={typographyStyle} onClick={sendEmail} >
          sonshinedevelopments@gmail.com
        </Typography>
        <Typography sx={typographyStyle} onClick={callPhone}>(229) 815-9535</Typography>
      </Container>
      <Typography variant="caption" style={{ color: "grey" }}>
     © Copyright 2023 Son-Shine Developments. All rights reserved. Website developed by <a href="https://www.themocreate.com" target="_blank" rel="noreferrer" style={{ color: "grey", ":visited":{color: "grey"}}}>MOCREATE, LLC.</a>
     </Typography>
    </Box>
    
   </>
  );
};

export default FooterSection;

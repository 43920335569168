import {
  Box,
  Typography,
  Grid,
  Rating,
  TextField,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import ReviewTile from "./ReviewTile";

const formStyle = {
  background: "black",
  input: { color: "white" },
  label: { color: "white" },
  textarea: { color: "white" },
  "& label.Mui-focused": {
    color: "red",
  },
};

const Ratings = () => {
  const [rating, setRating] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [reviewing, setReviewing] = useState(false);
  const [success, setSuccess] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    getReviews();
  });

  const getReviews = () => {
    fetch("https://sonshine-api.vercel.app/get_reviews")
      .then((res) => res.json())
      .then((docs) => {
        var average;
        var sum = 0;
        docs.map((review) => {
          sum += parseInt(review.rating);
          average = sum / docs.length;
          setAverageRating(average);
          return review;
        });
        setReviews(docs);
      })
      .catch(() => console.log("Something went wrong"));
  };
  const leaveReview = (e) => {
    setRating(e.target.value);
    setReviewing(true);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const saveReview = async () => {
    if (rating && name && description) {
      return await fetch("https://sonshine-api.vercel.app/save_review", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rating: rating,
          name: name,
          description: description,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setRating(null);
          setName("");
          setDescription("");
          setReviewing(false);
          setSuccess(true);
          getReviews();
        })
        .catch(() => {
          setSuccess(false);
          console.log("something went wrong");
        });
    }
  };
  return (
    <Box
      sx={{
        padding: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "50px",
        background: "#2b2b2b",
      }}
    >
      <Grid
        container
        sx={{
          // width: "min(90%, 1000px)",
          // display: "grid",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "1rem",
          padding: "1rem",
          background: "#333333",
          borderRadius: "25px",
        }}
      >
        {averageRating ? (
          <>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Typography variant="h2" color="white">
                {averageRating}
              </Typography>
              <Typography variant="h5" color="white">
                / 5
              </Typography>
              <Rating
                componenet="legend"
                precision={0.5}
                readOnly
                value={averageRating}
                onChange={leaveReview}
                emptyIcon={
                  <StarBorderPurple500Icon
                    fontSize="inherit"
                    sx={{ color: "white" }}
                  />
                }
                sx={{
                  //   "& .MuiRating-iconFilled": {
                  //     color: "red",
                  //   },
                  "& .MuiRating-iconFocus": {
                    color: "orange",
                  },
                }}
              >
                Rating
              </Rating>
              <Typography variant="h5" color="white">
                ({reviews.length})
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item>
            <Typography variant="h5" color="white">
              Be the first to leave a review.
            </Typography>
          </Grid>
        )}
        <Grid
          item
          sx={{
            background: "black",
            color: "white",
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <Typography>Rate and review</Typography>
          <Rating
            componenet="legend"
            value={rating}
            onChange={leaveReview}
            emptyIcon={
              <StarBorderPurple500Icon
                fontSize="inherit"
                sx={{ color: "white" }}
              />
            }
            sx={{
              //   "& .MuiRating-iconFilled": {
              //     color: "red",
              //   },
              "& .MuiRating-iconFocus": {
                color: "orange",
              },
            }}
          >
            Rating
          </Rating>
        </Grid>
        {reviewing && (
          // <Grid
          //   item
          //   sx={{
          //     display: "grid",
          //     alignItems: "center",
          //     justifyContent: "center",
          //   }}
          // >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              transition: "all 0.4s ease-in",
              // width: "60%",
            }}
          >
            <TextField
              variant="filled"
              label="Name"
              value={name}
              sx={formStyle}
              onChange={handleName}
              fullWidth
            />
            <TextField
              variant="filled"
              label="Description"
              sx={formStyle}
              value={description}
              onChange={handleDescription}
              multiline
              rows={4}
              fullWidth
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={saveReview}
              fullWidth
              disabled={(!name || !description) && true}
            >
              Submit
            </Button>
            {!success && (
              <Typography color="error">Something went wrong.</Typography>
            )}
          </form>
          // </Grid>
        )}
      </Grid>
      <Grid
        item
        sx={{ background: "#333333", padding: "1rem", borderRadius: "25px" }}
      >
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {reviews.map((review, index) => {
              return (
                <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
                  <ReviewTile review={review} />
                </div>
              );
            })}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </Grid>
    </Box>
  );
};

export default Ratings;

import { Box, Typography } from "@mui/material";
import React from "react";

const ServiceItem = ({ service }) => {
  return (
    <Box
      sx={{
        width: "300px",
        height: "300px",
        background: "black",
        display: "flex",
        flexDirection: "column",
        ":hover": {
          width: "350px",
          height: "350px",
          transition: "all 0.2s ease-in-out",
        },
      }}
    >
      <Typography variant="h5" sx={{ padding: "1rem", color: "white" }}>
        {service.title}
      </Typography>
      <img
        src={service.url}
        alt={service.title}
        style={{ width: "100%", height: "100%", opacity: "0.6" }}
      />
    </Box>
  );
};

export default ServiceItem;
import React from "react";

import { Typography, Container, Divider } from "@mui/material";

const AboutSectionItem = ({ icon, item }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon}
      <Typography
        variant="h5"
        sx={{ textTransform: "uppercase" }}
        color="secondary"
      >
        {item.title}
      </Typography>
      <Typography variant="body1" style={{ color: "grey", fontSize: "1.2rem"}}>{item.description}</Typography>
      <Divider orientation="vertical" />
    </Container>
  );
};

export default AboutSectionItem;

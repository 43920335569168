import { Box, Grid, Rating, Typography } from "@mui/material";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import React from "react";

const ReviewTile = ({review}) => {
  const date = new Date(review.createdDate);
  return (
    <Box sx={{background: "#333333", padding: "1rem",  borderRadius: "25px"}}>
      <Grid
        container
        sx={{
          background: "black",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >

          <Typography variant="h6" sx={{ color: "secondary.main" }}>
           {date.toLocaleDateString()}
          </Typography>
        <Grid
          item
          sx={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Rating
            value={review.rating}
            componenet="legend"
            readOnly
            emptyIcon={
              <StarBorderPurple500Icon
                fontSize="inherit"
                sx={{ color: "white" }}
              />
            }
          />
        </Grid>
        <Grid
          item
          sx={{
            color: "white",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              filter: "drop-shadow(-2px 2px 10px #FF1818)",
            }}
          >
            {review.name}
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: "300px" }}>
           {review.description}
          </Typography>
        </Grid>  
      </Grid>
    </Box>
  );
};

export default ReviewTile;

import { Box, Button, Container, Typography } from "@mui/material";
import AboutSectionItem from "./AboutSectionItem";
import map from "../resources/images/map.png";
import { item1, item2, item3 } from "./AboutData";
import React from "react";
import FlagIcon from "@mui/icons-material/Flag";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";

const AboutSection = ({scheduleConsult}) => {
  return (
    <Box id="about" sx={{ padding: "1rem" }}>
      <Container sx={{ width: "min(750px, 90%)" }}>
        <Typography variant="h4">Proudly Serving</Typography>
        <Typography variant="h2" sx={{ textDecoration: "underline" }}>
          Middle Georgia
        </Typography>
        <img
          src={map}
          alt="middle_georgia_map"
          style={{ width: "100%", height: "100%" }}
        />
      </Container>
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          // width: "min(800px, 90%)",
          padding: "1rem",
          gap: "1.5rem",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-2rem",
        }}
      >
        <AboutSectionItem item={item1} icon={<FlagIcon fontSize="large" />} />
        <AboutSectionItem
          item={item2}
          icon={<AccessTimeFilledRoundedIcon fontSize="large" />}
        />
        <AboutSectionItem
          item={item3}
          icon={<LockRoundedIcon fontSize="large" />}
        />
      </Container>
      <Button variant="contained" size="large" onClick={scheduleConsult}>
        Get a Free Quote Today!
      </Button>
    </Box>
  );
};

export default AboutSection;

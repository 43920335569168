import { Button, Typography, Box } from "@mui/material";
import React from "react";
import drill from "../resources/images/drill.png";

const HeroSection = () => {
  return (
    <Box
      id="home"
      sx={{
        background: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "2rem",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "white",
          fontSize: "min(4.5vw, 40px)",
          padding: "1rem",
          maxWidth: "800px",
        }}
      >
        A <u>reliable</u> and <u>affordable</u> construction company that
        specializes in home renovations for Middle Georgia.
      </Typography>
      <Button></Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          maxWidth: "500px"
 
        }}
      >
        <img
          src={drill}
          alt="hero_drill"
          style={{
            width: "100%",
            height: "100%",
            verticalAlign: "bottom",
            loading: "lazy",
          }}
        />
      </Box>
    </Box>
  );
};

export default HeroSection;

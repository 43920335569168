import {
  Box,
  Button,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import tools from "../resources/images/tools.png";
import emailjs from "@emailjs/browser";

const buttonStyle = {
  background: "white",
  input: { color: "red", fontSize: "1.5rem", border: "none" },
};

const emailRegex = /\S+@\S+\.\S+/;

const CareersSections = () => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false); 
  const form = useRef();

  const handleChange = (newValue) => {
    setFile(newValue);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e) => {
    const number = e.target.value;
    const cleaned = number.replaceAll(" - ", "").trim();
    var a = "";
    var b = "";
    var c = "";

    if (phone) {
      a = cleaned.substring(0, 3).trim();
      b = cleaned.substring(3, 6).trim();
      c = cleaned.substring(6).trim();
    }
    if (cleaned.length === 3) {
      setPhone(a + " - ");
    } else if (cleaned.length === 6) {
      setPhone(a + " - " + b);
    } else if (cleaned.length > 6) {
      if (cleaned.length < 11) {
        setPhone(a + " - " + b + " - " + c);
      }
    } else {
      setPhone(number);
    }
  };
  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    const validEmail = emailRegex.test(email);
    const validPhone = phone.replaceAll(' - ', '').trim().length === 10;
    setErrorMessage("")

    if (!validEmail) {
      setErrorMessage("Please enter a valid email address."); 
    } else if (!validPhone) {
      setErrorMessage("Please enter a valid phone number.")
    }

    if (name && email && phone && age && validEmail && validPhone && file) {
      emailjs
        .sendForm("sonshine", "application", form.current, "c31-EllXK63UiUmPW")
        .then(
          (response) => {
            setName('');
            setEmail('');
            setPhone('');
            setAge('');
            setFile(null);
            setErrorMessage('');
            setSuccess(true);
          },
          (error) => {
            setErrorMessage(error.text);
            setSuccess(false);
          }
        );
    }
  };
  return (
    <Box
      id="careers"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${tools})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "black",
        padding: "2rem",
      }}
    >
      <Grid
        container
        spacing={{ xs: 2 }}
        sx={{
          dislpay: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "1000px",
          background: "transparent",
          backdropFilter: "blur(5px)",
        }}
      >
        <Grid
          item
          sx={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "1rem",
            textAlign: "left",
            gap: "1rem",
            // background: "black",
          }}
          md={6}
        >
          <Typography
            variant="overline"
            sx={{
              textTransform: "uppercase",
              background: "red",
              padding: " 0 1rem",
              fontSize: "1.2rem",
            }}
          >
            Hiring Now
          </Typography>
          <Typography
            variant="h2"
            sx={{
              color: "white",
              textTransform: "uppercase",
              filter: "drop-shadow(0 0 .3rem red)",
            }}
          >
            Join the team.
          </Typography>
          <Typography variant="body1">
            We are seeking talented individuals to join our team. To apply,
            please complete the form and upload a copy of your updated resume.
            As part of the application process, selected candidates will be
            contacted for further evaluation, which may include interviews,
            assessments, or additional documentation.
          </Typography>
          {errorMessage && (
            <Typography variant="h6" color="error" sx={{ alignSelf: "center" }}>
              {errorMessage}
            </Typography>
          )}
        </Grid>
        { success ? (<Typography variant='h6'  sx={{ color: '#23f218', transition: "all 0.6s ease-in",  filter: "drop-shadow(0 0 10px green)"}}>Successfully submitted.</Typography>) : (<Grid
          item
          md={6}
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <form
            ref={form}
            encType="multipart/form-data"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: "1rem",
            }}
            // method="post"
            onSubmit={formSubmit}
          >
            <TextField
              variant="filled"
              label="Full Name"
              name="name"
              value={name}
              onChange={handleName}
              fullWidth
              sx={buttonStyle}
            />
            <TextField
              variant="filled"
              name="email"
              value={email}
              onChange={handleEmail}
              label="Email Address"
              fullWidth
              sx={buttonStyle}
            />
            <TextField
              variant="filled"
              label="Phone Number"
              name="phone"
              value={phone}
              onChange={handlePhone}
              fullWidth
              sx={buttonStyle}
            />
            <TextField
              name="age"
              value={age}
              onChange={handleAge}
              variant="filled"
              label="Age"
              fullWidth
              sx={buttonStyle}
            />
            <Input
              type="file"
              name="file"
              accept=".doc, .docx, .pdf"
              sx={{ color: "white", alignSelf: "flex-start" }}
              onChange={handleChange}
            ></Input>
            {name && email && phone && age && file ? (
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled
                color="secondary"
                type="submit"
                fullWidth
                sx={{
                  "&.Mui-disabled": {
                    color: "#c0c0c0",
                  },
                }}
              >
                Submit
              </Button>
            )}
          </form>
          <Typography variant="caption" sx={{ color: "white" }}>
            By clicking submit, you authorize Son-Shine Developments to use your
            information to contact you about employment. You may unsubscribe
            from these communications at any time.
          </Typography>
        </Grid>)}
      </Grid>
    </Box>
  );
};

export default CareersSections;

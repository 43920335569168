export const item1 = { 
       title: "Veteran Owned", 
    description: "6 years of service."
}

export const item2 = { 
    title: "Highly Skilled", 
 description: "12 years of experience."
}

export const item3 = { 
    title: "Insured & Bonded", 
 description: "Protection for property owners."
}
import "./App.css";
import {
  ThemeProvider,
  createTheme,
  AppBar,
  Container,
  Toolbar,
  Button,
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "./resources/images/logo.png";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import GalleryView from "./components/GalleryView";
import { useState } from "react";
import ServicesSection from "./components/ServicesSection";
import CareersSections from "./components/CareersSections";
import FooterSection from "./components/FooterSection";
import Ratings from "./components/Ratings";
import { Link } from "react-scroll";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#FF0000",
    },
    info: {
      main: "#3D0000",
    },
  },
  typography: {
    fontFamily: "Akshar",
    button: {
      fontFamily: "Akshar",
      fontWeight: 800,
      fontSize: "1rem",
    },
  },
});

const pages = ["Home", "About", "Services", "Portolio", "Careers"];

const style = {
  fontWeight: "bold",
  color: "#9A9A9A",
  display: "block",
  borderRadius: "0px",
  // ":hover": {
  //   color: "white",
  // },
};
const App = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("Home");

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const scheduleConsult = () => {
    window.open(
      "https://calendly.com/d/2kw-sdb-rp7/initial-consult?month=2023-06&date=2023-06-30"
    );
  };
  const list = () => (
    <Box
      sx={{
        width: "auto",
        background: "black",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((text) => (
           <Link
           key={text}
           className={active === text ? "active" : text.toString()}
           to={text.toLocaleLowerCase()}
           spy={true}
           smooth
           style={style}
           offset={-200}
           onClick={() => setActive(text)}>
          <ListItem
            key={text}
            disablePadding
            sx={{
              textTransform: "uppercase",
              color: "9A9A9A",
              textAlign: "center",
              fontSize: "200px",
            }}
          >
            <ListItemButton
              href={"#" + text.toLocaleLowerCase()}
              sx={{
                color: "grey",
                ":hover": {
                  color: "white",
                },
                ":active": {
                  color: "white",
                  borderBottom: "1px solid red",
                },
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {text}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AppBar
          position="sticky"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Container sx={{ padding: "1rem" }}>
            <Toolbar
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              disableGutters
            >
              {open ? (
                <CloseIcon
                  sx={{
                    display: { sm: "flex", md: "none" },
                    color: "white",
                    transition: "all 0.5 ease-in",
                  }}
                  onClick={toggleDrawer()}
                />
              ) : (
                <MenuIcon
                  sx={{
                    display: { sm: "flex", md: "none" },
                    color: "white",
                    transition: "all 0.5 ease-in",
                  }}
                  onClick={toggleDrawer()}
                />
              )}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  gap: "1rem",
                }}
              >
                {pages.map((page) => {
                  return (
                    <Link
                    key={page}
                      className={active === page ? "active" : page.toString()}
                      to={page.toLocaleLowerCase()}
                      spy={true}
                      smooth
                      style={style}
                      offset={-150}
                      onClick={() => setActive(page)}
                    >
                      <Button sx={style}> {page}</Button>
                    </Link>
                  );
                })}
              </Box>
              <Container
                sx={{
                  width: "min(50%, 300px)",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Link to="home" smooth offset={-150}>
                  <img
                    src={logo}
                    alt="logo_image"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Link>
              </Container>
              <Button
                variant="contained"
                color="secondary"
                onClick={scheduleConsult}
              >
                Get A Free Estimate
              </Button>
            </Toolbar>
          </Container>
          <Container
            sx={{
              width: "min(60%, 400px)",
              display: { sm: "flex", md: "none" },
            }}
          >
            <Link to={"home"} smooth offset={-150}>
              <img
                src={logo}
                alt="logo_image"
                style={{ width: "100%", height: "100%" }}
              />
            </Link>
          </Container>
        </AppBar>
        <Drawer
          anchor="bottom"
          open={open}
          variant="temporary"
          onClose={toggleDrawer()}
        >
          {list()}
        </Drawer>
        <HeroSection />
        <AboutSection scheduleConsult={scheduleConsult} />
        <ServicesSection />
        <GalleryView />
        <Ratings />
        <CareersSections />
        <FooterSection />
      </div>
    </ThemeProvider>
  );
};

export default App;
